import React, { useEffect, useState } from "react";
import Nav from "../shared/nav";
import Footer from "../shared/footer";
import { useLocation} from "react-router-dom";
import axios from "axios";
import AOS from 'aos';
import 'aos/dist/aos.css';

function Details () {
    let location = useLocation();
    const id = location.state.id;
    const [post, setPost] = useState(null);

    useEffect(()=> {
        AOS.init();
        getData();
    },[])

    const getData = () => {
        axios.get(`https://www.mvo.somee.com/api/Posts/${id}`)
            .then(data => {
                setPost(data.data);
            })
            .catch(resp => {
                console.error("Une erreur survenue : ", resp)
            })
    }

    return(
        <div>
            <Nav />
            {post?
            <section className="blog-details-area">
                 
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 m-auto text-center">
                            <h4 className="title" style={{color:'#003366', fontWeight:550}}>{post.title}</h4>
                            <div className="meta">
                                By
                                <a  className="author">{post.auteur}</a>
                                <a href="#">{post.dateCreation.split('T')[0]}</a>
                                {post.videoLink? post.videoLink.includes(".")?
                                        <a className="icon" href={post.videoLink} target="_blank">
                                        <div className="thumb bgcolor-f4 p-2 mt-2" style={{width:100, marginLeft:'43%'}}>
                                            <i className="lnr lnr-camera-video text-black" style={{color:'#003366'}}></i>
                                        </div>
                                        </a>
                                        :"":""
                                    }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid pl-0 pr-0">
                    <div className="row">
                    <div className="col-lg-12">
                        <div className="swiper-container post-gallery-slider-container">
                        <div className="swiper-wrapper post-gallery-slider">
                            <div className="swiper-slide gallery-slide-item">
                            {post.imageUrl.includes(".")? 
                                <img className="w-100" src={post.imageUrl} style={{height: '30%'}} alt="" />
                                :
                                <img className="w-100" style={{height:'150px'}} src="assets/img/shape/bg-01.png" alt="" />
                                }
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                    <div className="col-lg-10 col-xl-8 m-auto">
                        <div className="post-details-content">
                        <div className="post-details-body">
                            <div className="content">
                            <p className="text-pra">{post.description}</p>
                            <div dangerouslySetInnerHTML={{ __html: post.body }} />
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                    
                </section>
            : 
            <section className="blog-details-area">
                 
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 m-auto text-center">
                            <h4 className="title">Ce post <span>n'existe pas</span></h4>
                        </div>
                    </div>
                </div>
            </section>
            }
            <Footer duration={1200}/>
        </div>
    )
}

export default Details;