import React, { useEffect, useState } from "react";
import Nav from "../shared/nav";
import Footer from "../shared/footer";
import { Link } from "react-router-dom";
import axios from "axios";
import AOS from 'aos';
import 'aos/dist/aos.css';

function Home() {
    const [partners, setPartners] = useState([]);
    const [testimonies, setTestimonies] = useState([]);

    useEffect(() => {
        AOS.init();
        getPartners()
        getTestimonies()

    }, [])

    const getPartners = () => {
        axios.get(`https://www.mvo.somee.com/api/Partners`)
            .then(data => {
                if(data.status == 200) {
                    data.data[0].isActiv = true;
                    let donnee = [];

                    for (let i = 0; i < data.data.length; i += 3) {
                        donnee.push(data.data.slice(i, i + 3));
                    }
                    
                    setPartners(donnee);
                }
                else {
                    setPartners([]);
                }
            })
            .catch(err => {
                setPartners([]);
                console.log(err)
            })
    }

    const getTestimonies = () => {
        axios.get(`https://www.mvo.somee.com/api/Testimonies?page=1&count=50`)
        .then(data => {
            if(data.status == 200) {
                data.data[0].isActiv = true;
                
                setTestimonies(data.data)
            }
            else {
                setTestimonies([]);
            }
        })
        .catch(err => {
            setTestimonies([]);
            console.log(err)
        })
    }

    return(
        <div>
            <Nav />
            <main className="main-content site-wrapper-reveal">
                <section className="home-slider-area slider-default">
                    <div className="h-100" style={{backgroundImage:'url(assets/img/banner.png)', backgroundSize:'cover', backgroundRepeat:'no-repeat',padding:'2%'}}>
                    <div className="row justify-content-center">
                        <div className="col-lg-6">
                            <div className="content mb-md-60">
                                <div className="section-title xs-pr-15 pr-40" style={{marginLeft:'12%'}}>
                                    <h2 className="title mb-sm-6 mb-4 mt-6 text-jusrify" style={{fontSize:'42px', color:'#fff'}}><span style={{color:'#fff'}}>Empower Your <br /> Business</span> With Digital Solutions</h2>
                                    <span style={{fontWeight: 400, color:'#fff', textAlign:'justify'}}>Fournir des services et des solutions de la plus haute qualité à nos clients. Vous pouvez donc être assuré que notre équipe possède l’expertise requise pour vous aider à créer la bonne solution pour vous, vos utilisateurs cibles ou votre entreprise.</span>
                                    <div className="d-flex justify-content-start" style={{marginTop: '5%'}}>
                                        <Link to={'/about'}>
                                            <button id="ban-button" className="btn btn-theme" type="button">A propos de nous</button>
                                        </Link>
                                        <Link to={'/contact'}>
                                            <button className="btn btn-theme mx-3" type="button">Contactez-nous</button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="thumb" data-aos="fade-right" data-aos-duration="1200">
                                <img src="assets/img/shape/Group.png" alt="Roomedia-HasTech" />
                            </div>
                        </div>
                    </div>
                    <div className="shape-layer">
                        <div className="shape shape-one"></div>
                        <div className="shape shape-two"></div>
                        <div className="shape shape-three"></div>
                        <div className="shape shape-four"></div>
                        <div className="shape shape-five"></div>
                        <div className="shape shape-six"></div>
                        <div className="shape shape-seven"></div>
                        <div className="shape shape-eight"></div>
                    </div>
                    </div>
                    <div className="curve-style">
                    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" preserveAspectRatio="none" viewBox="0 0 100 100">
                        <path d="M 0 0 L0 100 L100 100 L100 0 Q 50 110 0 0"></path>
                    </svg>
                    </div>
                </section>
                
                <section className="about-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="thumb" data-aos="fade-right" data-aos-duration="1300">
                                    <img src="assets/img/about/img2.jpg" alt="Roomedia-HasTech" />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="about-content" data-aos="fade-up" data-aos-duration="1300">
                                    <div className="section-title">
                                    <h2 className="title"><span style={{color:'#003366'}}>À propos</span> de nous</h2>
                                    </div>
                                    <div className="accordian-content">
                                        <div className="accordion" id="accordionStyle">
                                            <div className="accordion-item">
                                                <div className="accordion-header" id="headingOne">
                                                    <button className="accordion-button" type="button" data-bs-toggle="collapse"
                                                    data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                    Présentation LogiqSoft
                                                    <i className="icon icon-plus icon_plus"></i>
                                                    <i className="icon icon-minus icon_minus-06"></i>
                                                    </button>
                                                </div>
                                                <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne"
                                                    data-bs-parent="#accordionStyle">
                                                    <div className="accordion-body">
                                                    LogiqSoft SARL, vous nous trouvez à Lubumbashi, à Kolwezi et en ligne, nous sommes une entreprise constituée d’une équipe forte et efficace d’experts numériques très engagée à contribuer de manière significative et positive à la conception, l’amélioration et la maintenance de vos solutions numériques de gestion, de communication, production et de sécurité de votre business Établie depuis 2019, notre expertise et nos méthodes de conception et de développement constituent le socle de notre stratégie numérique, sur ce vous êtes toujours sûr d’obtenir les solutions numériques à jour à la hauteur de vos attentes remplissant vos exigences d’innovation afin de faire la différence face à la concurrence. Nous sommes motivés et passionnés par les nouvelles technologies et la manière dont elles vont résoudre vos difficultés business
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <a href="/about" className="btn btn-theme">Voir plus</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                
                <section className="service-area" style={{backgroundImage:'url(assets/img/shape/foooter-shape-02.png)', backgroundSize:'cover', backgroundRepeat:'no-repeat', marginTop:'-15%'}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 m-auto">
                                <div className="section-title text-center" data-aos="fade-up" data-aos-duration="1100">
                                    <h2 className="title text-uppercase"><span style={{color:'#003366'}}>Nos Services</span> Pour vous</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="icon-box-style1 mb-sm-70 mb-lg-80 mb-100" data-aos="fade-up" data-aos-duration="1200">
                                    <div className="icon-box-list">
                                        <div className="icon-box-item">
                                            <div className="icon-box">
                                            <img className="icon-img" src="assets/img/icons/data.png" alt="Icon" />
                                            </div>
                                            <div className="content">
                                            <h4><a href="#">Développements Logiciles</a></h4>
                                            <p>Nous concevons, architecturons et développons des produits logiciels élégants. Les applications que nous concevons offrent des expériences utilisateur convaincantes et de beaux designs visuels.</p>
                                            </div>
                                            <span className="icon-box-number">01</span>
                                        </div>
                                        <div className="icon-box-item">
                                            <div className="icon-box">
                                            <img className="icon-img" src="assets/img/icons/supp.png" alt="Icon" />
                                            </div>
                                            <div className="content">
                                            <h4><a href="#">IT Support</a></h4>
                                            <p>Nous travaillons avec de nombreux types de clients IT ayant des besoins uniques et nous élaborons un plan informatique et un budget pour chaque client afin de les aider à obtenir les meilleures services et produits.</p>
                                            </div>
                                            <span className="icon-box-number">02</span>
                                        </div>
                                        <div className="icon-box-item">
                                            <div className="icon-box">
                                            <img className="icon-img" src="assets/img/icons/digit.png" alt="Icon" />
                                            </div>
                                            <div className="content">
                                            <h4><a href="#">Digital Marketing</a></h4>
                                            <p>Nous travaillons sur l'ensemble des activités marketing déployées en ligne pour entrer en relation avec des clients ou prospects. La tenue d'un site web ou blog, les réseaux sociaux, ou la publicité digitale.</p>
                                            </div>
                                            <span className="icon-box-number">03</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                
                <section className="portfolio-area portfolio-default-area" style={{marginTop:'-20%'}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-7" data-aos="fade-left" data-aos-duration="1000">
                                <div className="section-title">
                                    <h2 className="title px-3 pt-2"><span style={{color:'#003366'}}>Pourquoi</span> nous choisir</h2>
                                </div>
                                <div className="post-details-content">
                                    <div className="post-details-body px-3">
                                        <div className="content">
                                            <p className="text-pra">Nous sommes spécialisés dans <span style={{color:'#003366'}}>le développement de solutions automatisées variées</span> dans le domaine de la gestion des ressources humaines et des opérations en entreprise, qui sont conçues pour utiliser les normes internationales.</p>
                                            <div className="row">
                                                <div className="col-md-6 p-2">
                                                    <img className="icon-img" src="assets/img/icons/comp.png" alt="Icon" />
                                                        <h5 className="title">Mobilité en Entreprise</h5>
                                                        <p>Offrez à vos employés des applications personnalisées de mobilité d'entreprise, qui leur permettent d'accéder à ce dont ils ont besoin là où ils en ont besoin</p>
                                                </div>
                                                <div className="col-md-6 p-2">
                                                    <img className="icon-img" src="assets/img/icons/iot.png" alt="Icon" />
                                                        <h5 className="title">Développement IOT pour Les Industries</h5>
                                                        <p>En tant que entreprise de développement IoT personnalisé, nous travaillons avec une variété d’industries Transport, Agriculture Et bien plus</p>
                                                </div>
                                                <div className="col-md-6 p-2">
                                                    <img className="icon-img" src="assets/img/icons/coding.png" alt="Icon" />
                                                        <h5 className="title">Logiciel de bureau</h5>
                                                        <p>Les logiciels obsolètes peuvent entraîner de nombreux problèmes pour votre entreprise, Logiqsoft peut moderniser d’anciens logiciels de bureau</p>
                                                    
                                                </div>
                                                <div className="col-md-6 p-2">
                                                        <img className="icon-img" src="assets/img/icons/app.png" alt="Icon" />
                                                        <h5 className="title">Développement Mobile et Web</h5>
                                                        <p>Lorsque vous créez d'abord pour Android, l'utilisation des logiciels Web sur les ordinateur portable ou de bureau est frequent.</p>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <div className="thumb" data-aos="fade-right" data-aos-duration="1300">
                                    <img src="assets/img/about/img3.jpg" style={{marginTop:'15%'}} height={420} alt="Roomedia-HasTech" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
      
                <section className="testimonial-area testimonial-default-area" style={{backgroundImage:'url(assets/img/shape/bg-01.png)', backgroundSize:'cover', backgroundRepeat:'no-repeat', marginTop:'-18%'}} data-aos="fade-up" data-aos-duration="1300">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-9 col-md-7 col-lg-6 col-xl-5">
                                <div className="section-title">
                                    <h2 className="title">Plusieurs de nos <span style={{color:'#003366'}}>cas d'etudes</span></h2>
                                <div className="icon">
                                    <img className="icon-img" src="assets/img/icons/quote.png" alt="Icon" />
                                    <img className="icon-shape" src="assets/img/shape/t01.png" alt="Roomedia-Image" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-xl-7">
                            <div className="testimonial-content-area">
                                <div className="swiper-container testimonial-slider-container">
                                    <div className="swiper-wrapper testimonial-slider">
                                        <div className="swiper-slide testimonial-single">
                                            <div className="row portfolio-grid gutter-50">
                                                <div className="col-md-12 col-sm-12 portfolio-item">
                                                    <div className="inner-content">
                                                        <div className="thumb">
                                                            <a><img src="assets/img/photos/fec3.png" alt="Roomedia-Portfolio"/></a>
                                                        </div>
                                                        <div className="portfolio-info">
                                                            <h3 className="title">Développement</h3>
                                                            <a  className="category">Présentation Logiciel de Car Traking à la FEC</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                       <div className="swiper-slide testimonial-single">
                                            <div className="row portfolio-grid gutter-50">
                                                <div className="col-md-12 col-sm-12 portfolio-item">
                                                    <div className="inner-content">
                                                        <div className="thumb">
                                                            <a><img src="assets/img/photos/lima.jpg" alt="Roomedia-Portfolio"/></a>
                                                        </div>
                                                        <div className="portfolio-info">
                                                            <h3 className="title">Environement</h3>
                                                            <a  className="category">Automatique irrigation</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="swiper-button-prev"><i className="lnr lnr-arrow-left"></i></div>
                                    <div className="swiper-button-next"><i className="lnr lnr-arrow-right"></i></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </section>
                
                <section className="blog-area blog-default-area" style={{marginTop:'-12%'}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 py-4">
                                <div className="section-title">
                                    <h2 className="title text-uppercase text-center"><span style={{color:'#003366'}}>Comment</span> Nous procédons</h2>
                                    <br /><br />
                                </div>
                            </div>
                        </div>
                        <div className="row p-2">
                            <div className="col-lg-12 py-8">
                                <div className="blog-content-area py-8">
                                    <div className="row" data-aos="fade-up" data-aos-duration="1300">
                                        <div className="col-md-3 p-4">
                                            <img className="icon-img" style={{marginLeft:'35%'}} src="assets/img/icons/humain.png" alt="Icon" />
                                            <h5 className="title text-center">Atelier de Stratégie</h5>
                                            <p className="text-center">Nous recommandons des fonctionnalités de la solution et des stratégies numériques et digitales qui vous aident à atteindre vos objectifs commerciaux.</p>
                                        </div>
                                        <div className="col-md-3 p-4">
                                            <img className="icon-img" style={{marginLeft:'35%'}} src="assets/img/icons/strategy.png" alt="Icon" />
                                            <h5 className="title text-center">Wireframes</h5>
                                            <p className="text-center">Nous épluchons des couches de fonctionnalités « ergonomiques » pour aiguiser la proposition de valeur fondamentale de votre solution.</p>
                                        </div>
                                        <div className="col-md-3 p-4">
                                            <img className="icon-img" style={{marginLeft:'35%'}} src="assets/img/icons/prototype.jpg" alt="Icon" />
                                            <h5 className="title text-center">Prototypages</h5>
                                            <p className="text-center">Nous développons rapidement des fonctionnalités de la solution que vous pouvez expérimenter.</p>
                                        </div>
                                        <div className="col-md-3 p-4">
                                            <img className="icon-img" style={{marginLeft:'35%'}} src="assets/img/icons/data.png" alt="Icon" />
                                            <h5 className="title text-center">Développement</h5>
                                            <p className="text-center">Nous utilisons un processus de développement axé sur la conception pour définir chaque écran et fonctionnalité de la solution.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="testimonial-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="testimonial-content-area" >
                                    <div id="carouselExampleControlsNoTouching" className="carousel slide" data-bs-touch="false">
                                        <div className="carousel-inner">
                                            
                                            {partners.map(data => 
                                                <div key={data[0].id} className={data[0].isActiv?"carousel-item active":"carousel-item"} style={{marginLeft:'10%'}}>
                                                    {data.map(partner =>
                                                        <div key={partner.id+'x'} className="col-md-4 p-1" style={{display:'inline-block'}}>
                                                            <img src={partner.imageLink} alt={partner.company} />
                                                        </div>
                                                    )}
                                                </div>
                                                            )}
                                        </div>
                                        <button style={{border:'none', backgroundColor:'transparent', color:'black'}} className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControlsNoTouching" data-bs-slide="prev">
                                            <span style={{fontSize:'30px'}}><i className="lnr lnr-chevron-left"></i></span>
                                        </button>
                                        <button style={{border:'none', backgroundColor:'transparent', color:'black'}} className="carousel-control-next" type="button" data-bs-target="#carouselExampleControlsNoTouching" data-bs-slide="next">
                                            <span style={{fontSize:'30px'}}><i className="lnr lnr-chevron-right"></i></span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="divider-area newsletter-area" style={{marginTop:'-5%', marginBottom:'-10%'}}>
                    <div className="container position-relative">
                    <div className="row">
                        <div className="col-lg-12">
                        <div className="newsletter-content text-center">
                            <div className="row">
                            <div className="col-lg-6 m-auto">
                                <div className="section-title">
                                    <h2 className="title text-uppercase">Donnez un coup de pouce à <span style={{color:'#003366'}}>vos activités</span></h2>
                                </div>
                                <p>N'hesitez pas a nous contacter</p>
                                <button className="btn btn-theme" type="button">Contactez-nous</button>
                            </div>
                            </div>
                            <img className="bg-shape-img" src="assets/img/shape/bg-shape-01.png" alt="Image" />
                        </div>
                        </div>
                    </div>
                    <div className="shape-layer">
                        <div className="shape shape-one"></div>
                        <div className="shape shape-two"></div>
                        <div className="shape shape-three"></div>
                        <div className="shape shape-four"></div>
                        <div className="shape shape-five"></div>
                        <div className="shape shape-six"></div>
                        <div className="shape shape-seven"></div>
                        <div className="shape shape-eight"></div>
                    </div>
                    </div>
                </section>
                
                <section className="testimonial-area" data-aos="fade-up" data-aos-duration="1300">
                    <div className="container" style={{paddingTop: "10%"}}>
                        <div className="row">
                            <div className="col-xl-5">
                                <div className="section-title">
                                    <h2 className="title">Quelques temoignages de <span style={{color:'#003366'}}>nos clients</span></h2>
                                
                            </div>
                        </div>
                        <div className="col-xl-7">
                                <div id="carouselExampleAutoplaying" className="carousel slide" data-bs-ride="carousel">
                                    <div className="carousel-inner p-4">
                                        {testimonies.map(testimonie =>
                                        <div className={testimonie.isActiv?"carousel-item active":"carousel-item"}>
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <img src="assets/img/quote-open.png" alt="" width={50} height={50} />
                                                </div>
                                                <div className="col-md-8 p-1">
                                                    <p style={{textAlign:'justify'}}>{testimonie.content}</p>
                                                    <h6 style={{textAlign:'end'}}>{testimonie.author}</h6>
                                                </div>
                                                <div className="col-md-2">
                                                <img src="assets/img/quote-close.png" alt="" width={50} height={50} />
                                                </div>
                                            </div>
                                        </div>
                                        )}
                                    </div>
                                </div>
                        </div>
                    </div>
                    </div>
                </section>
                
            </main>
            <Footer duration={1200} />
        </div>
    )
}

export default Home;