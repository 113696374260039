import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Nav = ({taille}) => {
  const [isMobile, setIsMobile] = useState(false);
  const [isMenu, setIsMenu] = useState(false);
  const [height, setHeight] = useState(890);

  useEffect(()=> {
    handleResize();
    
    const resizeObserver = new ResizeObserver(handleResize);
    resizeObserver.observe(document.getElementById("body"));
    return () => resizeObserver.disconnect();
  },[])

  const handleResize = () => {
    const size = window.getComputedStyle(document.getElementById("body")).width;
    if (parseInt(size.split('p')[0]) < 975) {
      setIsMobile(true)
      if(taille) {
        taille(1220)

        if(parseInt(size.split('p')[0]) < 750) {
          //console.log("existe")
          taille(2500)
        }
      }
    }
    else {
      setIsMobile(false)
      setIsMenu(false)

      if(taille) {
        taille(890)
      }
    }
  }

  const handleOpen = () => {
    //console.log("ici")
    setIsMenu(true);
  }

  const handleClose = () => {
    setIsMenu(false);
  }

    return(
      <>
      <header className="header-area header-default sticky-header">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-4 col-sm-4 col-md-4 col-lg-2">
            <div className="header-logo-area">
              <a href="/">
                <img className="logo-main" src="assets/img/logo-bleu.png" alt="Logo" />
                <img className="logo-light" src="assets/img/logo-blanc.png" alt="Logo" />
              </a>
            </div>
          </div>
          <div className="col-7 col-sm-7 col-md-7 col-lg-9">
            <div className="header-align justify-content-end">
              <div className="header-navigation-area navigation-style-two">
                <ul className="main-menu nav justify-content-end">
                  <li><a href="/">Accueil</a></li>
                  <li className="has-submenu"><a href="#">Nos Services</a>
                    <ul className="submenu-nav">
                      <li><Link to="/developpement-logiciel">Développements Logiciles</Link></li>
                      <li><Link to="/it-support">IT Support</Link></li>
                      <li><Link to="/digital-marketing">Digital Marketing</Link></li>
                    </ul>
                  </li>
                  <li className="has-submenu"><a href="#">Pages</a>
                    <ul className="submenu-nav">
                      <li><Link to="/about">A Propos</Link></li>
                      <li><Link to="/solution">Solutions</Link></li>
                      <li><Link to="/contact">Contact</Link></li>
                    </ul>
                  </li>
                  <li><Link to="/blog">Blog</Link>
                  </li>
                </ul>
              </div>
            </div>
            
          </div>
          <div className="col-1 col-sm-1 col-lg-1">
            {isMobile? 
              <div className="row" style={{background:'transparent'}}>
                <button className="btn-search" onClick={handleOpen} style={{background:'transparent', fontWeight:800,fontSize:20, border: 'none', color: '#fff'}}>
                  <span className="icon icon-menu lnr lnr-menu"></span>
                </button>
              </div>
              :""}
            </div>
        </div>
      </div>
    </header>
    {isMenu?
      <aside className="off-canvas-wrapper active" data-aos="fade-right" data-aos-duration="0">
      <div className="off-canvas-inner">
        <div className="off-canvas-overlay"></div>
        <div className="off-canvas-content">
          <div className="off-canvas-header">
            <div className="logo-area">
              <a href="/"><img src="assets/img/logo.png" alt="Logo" /></a>
            </div>
            <div className="close-action">
              <button className="btn-close" onClick={handleClose}><i className="lnr lnr-cross"></i></button>
            </div>
          </div>

          <div className="off-canvas-item">
            <div className="res-mobile-menu menu-active-one">
            <ul className="main-menu justify-content-center">
                  <li><a href="/">Accueil</a></li>
                  <li className="has-submenu"><a href="#">Nos Services</a>
                    <ul className="submenu-nav">
                      <li><Link to="/developpement-logiciel">Développements Logiciles</Link></li>
                      <li><Link to="/it-support">IT Support</Link></li>
                      <li><Link to="/digital-marketing">Digital Marketing</Link></li>
                    </ul>
                  </li>
                  <li className="has-submenu"><a href="#">Pages</a>
                    <ul className="submenu-nav">
                      <li><Link to="/about">A Propos</Link></li>
                      <li><Link to="/solution">Solutions</Link></li>
                      <li><Link to="/contact">Contact</Link></li>
                    </ul>
                  </li>
                  <li><Link to="/blog" state={{height: height}}>Blog</Link>
                  </li>
                </ul>
            </div>
          </div>
          <div className="off-canvas-footer"></div>
        </div>
      </div>
    </aside>
    :""
    }
</>
    )
}

export default Nav;