import { Routes, Route } from 'react-router-dom';
import Home from './pages/home';
import Apropos from './pages/apropos';
import Contact from './pages/contact';
import Blog from './pages/blog';
import Digital from './pages/digital';
import Logiciel from './pages/logiciel';
import Support from './pages/support';
import Details from './pages/detail';
import React from 'react';
import Solution from './pages/solution';

function App() {
  return (
    <Routes>
        <Route path="/" element={<Home />} />
        <Route path='/about' element={<Apropos />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/blog' element={<Blog />} />
        <Route path='/details' element={<Details />} />
        <Route path='/solution' element={<Solution />} />
        <Route path='/digital-marketing' element={<Digital />} />
        <Route path='/developpement-logiciel' element={<Logiciel />} />
        <Route path='/it-support' element={<Support />} />
    </Routes>
  );
}

export default App;
