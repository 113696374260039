import React, { useEffect } from "react";
import Nav from "../shared/nav";
import Footer from "../shared/footer";
import AOS from 'aos';
import 'aos/dist/aos.css';

function Contact() {
    useEffect(() => {
        AOS.init();
    },[])

    return(
        <div>
            <Nav />
            <main class="main-content site-wrapper-reveal">
    
                <section class="page-title-area">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="section-title pt-50 text-center pb-md-30 pb-55">
                                <h2 class="title"><span style={{color:'#003366'}}>Contactez </span>Nous</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
    
                <div>
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="contact-map-area">              
                                <iframe src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Lubumbashi,%20av%20Kasai+(My%20Business%20Name)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
   
                <section class="contact-area" style={{backgroundImage:'url(assets/img/shape/foooter-shape-02.png)', backgroundSize:'cover', backgroundRepeat:'no-repeat'}}>
                    <div class="container">
                        <div class="row">
                        <div class="col-lg-10 col-xl-8 m-auto text-center">
                            <h2 class="title">Contactez-nous facilement</h2>
                            <p>Nous travaillons avec divers entrepreneurs et entreprises dans des secteurs allant de l’agriculture au fret, vous pouvez donc être assuré que notre équipe possède l’expertise requise pour vous aider à créer la bonne solution pour vous, vos utilisateurs cibles ou votre entreprise.</p>
                            <br /><br />
                        </div>
                        </div>
                        <div class="row">
                        <div class="col-lg-12 col-xl-10 m-auto">
                            <div class="contact-info-content-style">
                            <div class="info-item info-item2">
                                <div class="info-title">
                                <h4>Contact Directly</h4>
                                <div class="icon">
                                    <i class="icon_phone"></i>
                                </div>
                                </div>
                                <div class="info-content">
                                <a href="tel://+243 833 613 857">+243 833 613 857</a>
                                </div>
                            </div>
                            <div class="info-item">
                                <div class="info-title">
                                <h4>Nos bureaux</h4>
                                <div class="icon">
                                    <i class="icon_pin_alt"></i>
                                </div>
                                </div>
                                <div class="info-content">
                                <p>232, Ave Lumumba, Lubumbashi, Haut-Katanga</p>
                                </div>
                            </div>
                            <div class="info-item">
                                <div class="info-title">
                                <h4>Notre Adresse</h4>
                                <div class="icon">
                                    <i class="icon_mail_alt"></i>
                                </div>
                                </div>
                                <div class="info-content">
                                <p><a href="mailto://services@logiqsoft.io">services@logiqsoft.io</a> | <a href="mailto://info@logiqsoft.io">info@logiqsoft.io</a></p>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer duration={1200} />
        </div>
    )
}

export default Contact;