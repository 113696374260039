import React, { useState } from "react";

const Footer = ({duration}) => {
  const [year, setYear] = useState(()=>{
    const full = new Date;
    return full.getFullYear();
  });

    return(
        <footer className="footer-area reveal-footer" style={{backgroundColor:'#003366'}}>
          <img src="assets/img/shape/foooter-shape-01.png"/>
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-lg-3 col-xl-3">
                <div className="widget-item mt-0">
                  <div className="about-widget">
                    <a className="footer-logo" href="/">
                      <img src="assets/img/logo-blanc.png" alt="Logo" />
                    </a>
                  </div>
                  <div className="widget-copyright text-white">
                    <p>© {year} <span style={{color:'#009fe3', fontWeight:'650'}}>Logiqsoft</span>. <br /><i className="icon_heart"></i> Tous droits réservés</p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 col-xl-4">
                <div className="widget-item sm-pl-0 pl-80 pt-10">
                  <h4 className="widget-title" style={{color:'#fff'}}>Contact</h4>
                  <ul className="widget-contact-info">
                    <li className="info-address" style={{color:'#fff'}}>Lubumbashi, Kolwezi</li>
                    <li className="info-mail"><a href="mailto://services@logiqsoft.io">services@logiqsoft.io</a></li>
                    <li className="info-phone"><a href="tel://+243 833 613 857">+243 833 613 857</a></li>
                  </ul>
                </div>
              </div>
              <div className="col-md-10 col-lg-5 col-xl-5">
                <div className="widget-item widget-menu-wrap-item">
                  <nav className="widget-menu-wrap">
                    <ul className="nav-menu nav-menu-three nav">
                      <li><a href="/developpement-logiciel">Logiciel</a></li>
                      <li><a href="/it-support">Support</a></li>
                      <li><a href="/digital-marketing">Marketing</a></li>
                      <li><a href="/blog">Blog</a></li>
                    </ul>
                  </nav>
                  <div className="widget-social-icons social-icons-style-three">
                    <a href="https://www.linkedin.com/company/logiqsoft" target="_blank"><i className="social social_linkedin"></i></a>
                    <a href="https://web.facebook.com/logiqsoft" target="_blank"><i className="social social_facebook"></i></a>
                    <a href="https://twitter.com/LogiqSoft" target="_blank"><i className="social social_twitter"></i></a>
                    <a href="https://www.instagram.com/logiqsoft/" target="_blank"><i className="social social_instagram"></i></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
       
    )
}

export default Footer;