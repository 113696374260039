import React, { useEffect } from "react";
import Nav from "../shared/nav";
import Footer from "../shared/footer";
import AOS from 'aos';
import 'aos/dist/aos.css';

function Digital() {
    useEffect(() => {
        AOS.init();
    },[])

    return(
        <div>
            <Nav />
            <main className="main-content site-wrapper-reveal">
                <section className="page-title-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="page-title-content text-center">
                                    <h2 className="title" style={{color:'#003366'}}><span>DIGITAL MARKETING</span></h2>
                                </div>
                            </div>
                            <div className="shape-layer">
                                <div className="shape shape-one"></div>
                                <div className="shape shape-two"></div>
                                <div className="shape shape-three"></div>
                                <div className="shape shape-four"></div>
                                <div className="shape shape-five"></div>
                                <div className="shape shape-six"></div>
                                <div className="shape shape-seven"></div>
                                <div className="shape shape-eight"></div>
                            </div>
                        </div>
                    </div>
                </section>
                
                <section>
                    <div className="container lg-pt-0 pt-45">
                        <div className="row divider-content-style1 p-3" data-aos="fade-down" data-aos-duration="1000">
                            <div className="col-lg-12 col-xl-5">
                                <div className="thumb">
                                    <img className="ml-[10%] mb-10" src="assets/img/photos/digital.jpg" width={590} height={520} alt="Image" />
                                </div>
                            </div>
                            <div className="col-lg-12 col-xl-6 offset-xl-1">
                                <div className="content">
                                    <div className="section-title">
                                        <h3 className="text-black text-bold">Pourquoi nous choisir ?</h3>
                                        <p>Nous a accompagnons des entreprises de toutes tailles. Nous vous aidons à atteindre vos objectifs marketing en vous proposant des solutions digitales sur-mesure.</p>
                                        <div className="row">
                                            <div className="col-md-6 p-2">
                                                <img className="icon-img" src="assets/img/icons/digit.png" alt="Icon" />
                                                <h5 className="text-black my-3">Stratégie digitale</h5>
                                                <p>Analyse de votre marché et de vos concurrents, Définition de vos objectifs marketing, Création d'une stratégie digitale personnalisée, Mise en place d'un plan d'action</p>
                                            </div>
                                            <div className="col-md-6 p-2">
                                                <img className="icon-img" src="assets/img/icons/humain.png" alt="Icon" />
                                                <h5 className="text-black my-3">Création de contenu</h5>
                                                <p>Rédaction de contenus web optimisés pour le SEO, Création de visuels et de vidéos attractifs, Production de newsletters et d'emailings, Community management sur les réseaux sociaux</p>
                                            </div>
                                        </div>
                                        <div className="accordian-content">
                                            <div className="accordion" id="accordionStyle">
                                                <div className="accordion-item">
                                                    <div className="accordion-header" id="headingOne">
                                                        <button className="accordion-button" type="button" data-bs-toggle="collapse"
                                                            data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                            Création d'une identité visuelle
                                                            <i className="icon icon-plus icon_plus"></i>
                                                            <i className="icon icon-minus icon_minus-06"></i>
                                                        </button>
                                                    </div>
                                                    <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne"
                                                        data-bs-parent="#accordionStyle">
                                                        <div className="accordion-body">
                                                        L'identité visuelle est l'ensemble des éléments graphiques qui permettent de reconnaître votre entreprise
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item">
                                                    <div className="accordion-header" id="headingTwo">
                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                            data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                                Mise en place d'une stratégie d'email marketing
                                                            <i className="icon icon-plus icon_plus"></i>
                                                            <i className="icon icon-minus icon_minus-06"></i>
                                                        </button>
                                                    </div>
                                                    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo"
                                                        data-bs-parent="#accordionStyle">
                                                        <div className="accordion-body">
                                                        L'email marketing permet de communiquer directement avec vos clients et prospects par email.
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item">
                                                    <div className="accordion-header" id="headingThree">
                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                            data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                            Community management sur les réseaux sociaux
                                                            <i className="icon icon-plus icon_plus"></i>
                                                            <i className="icon icon-minus icon_minus-06"></i>
                                                        </button>
                                                    </div>
                                                    <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree"
                                                        data-bs-parent="#accordionStyle">
                                                        <div className="accordion-body">
                                                        Nous animons et gérons votre communauté sur les réseaux sociaux. A fin de developper votre visibilité, accroître votre notoriété et fidéliser vos clients.
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                <div className="shape-thumb-style1">
                    <img src="assets/img/shape/service-details-shape.png" alt="Image" />
                </div>
                </section>

                <section className="divider-area newsletter-area">
                    <div className="container position-relative">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="newsletter-content text-center">
                                    <div className="row">
                                    <div className="col-lg-10 m-auto">
                                        <div className="section-title">
                                            <h2 className="title text-uppercase">Ne manquez pas une occasion en or de développer <span style={{color:'#003366'}}>votre entreprise.</span></h2>
                                        </div>
                                        <p className="text-black"><span style={{color:'#003366'}}>Découvrez l'évolution de votre entreprise </span>En bref, si vous n'êtes pas sur les réseaux sociaux, vous manquez une occasion en or de développer votre entreprise.</p>
                                        <a href="/contact"><button className="btn btn-theme" type="button">Contactez-nous</button></a>
                                    </div>
                                    </div>
                                    <img className="bg-shape-img" src="assets/img/shape/bg-shape-01.png" alt="Image" />
                                </div>
                            </div>
                        </div>
                        <div className="shape-layer">
                            <div className="shape shape-one"></div>
                            <div className="shape shape-two"></div>
                            <div className="shape shape-three"></div>
                            <div className="shape shape-four"></div>
                            <div className="shape shape-five"></div>
                            <div className="shape shape-six"></div>
                            <div className="shape shape-seven"></div>
                            <div className="shape shape-eight"></div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer duration={1200} />
        </div>
    )
}

export default Digital;