import React, {useState, useEffect} from "react";
import Nav from "../shared/nav";
import Footer from "../shared/footer";
import axios from "axios";
import AOS from 'aos';
import 'aos/dist/aos.css';

function Solution() {
    const [solutions, setSolutions] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        AOS.init();
        getSolutions()

    }, [])

    const getSolutions = () => {
        axios.get(`https://www.mvo.somee.com/api/Solutions`)
        .then(data => {
            if(data.status == 200) {
                data.data[0].isActiv = true;
                
                setSolutions(data.data)
            }
            else {
                setSolutions([]);
            }
            setIsLoading(false);
        })
        .catch(err => {
            setIsLoading(false);
            setSolutions([]);
            console.log(err)
        })
    }

    return(
        <div>
            <Nav />
            <main className="main-content site-wrapper-reveal">
                    
                <section className="page-title-area" style={{backgroundImage:'url(assets/img/shape/bg-01.png)', backgroundSize:'cover', backgroundRepeat:'no-repeat'}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center pt-50 pb-lg-30 pb-50">
                                    <h2 className="title">Trouvez ici <span style={{color:'#003366'}}> Nos Solutions</span></h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="testimonial-area">
                    <div className="container p-4">
                    {isLoading? 
                        <div className="spinner-border text-primary" style={{width:70, height:70, marginLeft:'45%'}} role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>:""}
                        <div className="row" id="test-id">
                            {solutions.map(sol =>
                            <div key={sol.id} className="col-md-4">
                                <div className="card shadow m-1 p-2 mb-5 bg-body-tertiary rounded">
                                        {sol.logoLink.includes(".")? 
                                            <img className="card-img-top" src={sol.logoLink} alt="" />
                                        :
                                            <div className="card-img-top" style={{backgroundImage:'url(assets/img/banner-nav.png)', backgroundSize:'cover', backgroundRepeat:'no-repeat'}}>
                                                <h6  className="text-center" style={{paddingTop:'25%', marginBottom:'25%', color:'#fff'}}>No Image</h6>
                                            </div>
                                        }
                                    <div class="card-body">
                                        <h5 class="card-title">{sol.solutionName} <button type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample" className="btn btn-light mx-2"><i className="lnr lnr-chevron-down"></i></button></h5>
                                        <div class="collapse" id="collapseExample">
                                            <p>
                                                {sol.description}
                                            </p>
                                        </div>
                                        <a href={sol.goToLink} target="_blank" class="card-text"><b><p style={{textAlign:'end'}}><i className="lnr lnr-link"></i></p></b></a>
                                    </div>
                                </div>
                            </div>
                            )}
                        </div>
                    </div>
                </section>
            </main>
            <Footer duration={1200} />
        </div>
    )
}

export default Solution;