import React, { useEffect, useState, useRef } from "react";
import Nav from "../shared/nav";
import Footer from "../shared/footer";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import AOS from 'aos';
import 'aos/dist/aos.css';

function Blog () {
    const currentUrl = window.location.href;
    const [posts, setPosts] = useState([]);
    const [page, setPage] = useState(1)
    const [height, setHeight] = useState(890);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(()=> {
        AOS.init();
        getData(page);
    },[])

    const handleHeight = (num) => {
        //console.log(num)
        setHeight(num)
    }

    const getData = (current) => {
        
        axios.get(`https://www.mvo.somee.com/api/Posts?page=${current}&count=6`)
            .then(data => {
                if(data.status == 200) {
                    setPosts(data.data);
                }
                else {
                    setPosts([]);
                }
                setIsLoading(false);
            })
            .catch(err => {
                setIsLoading(false)
                setPosts([]);
                console.log(err)
            })
            
    }

    const handleNext = () => {
        const currentPage = page + 1;
        //console.log(currentPage)
        setPage(currentPage);
        getData(currentPage);

    }

    const handlePreviews = () => {
        const previews = page - 1;
        //console.log(previews)
        setPage(previews);
        getData(previews);

    }

    return(
        <div>
            <Nav taille={handleHeight} />
            <section className="page-title-area" style={{backgroundImage:'url(assets/img/shape/bg-01.png)', backgroundSize:'cover', backgroundRepeat:'no-repeat'}}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title text-center pt-50 pb-lg-30 pb-50">
                                <h2 className="title">Les News Sur<span style={{color:'#003366'}}> Notre Blog</span></h2>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="blog-grid-area pb-1">
                <div className="container">
                    <div className="row p-4">
                        {isLoading? 
                        <div className="spinner-border text-primary" style={{width:70, height:70, marginLeft:'45%'}} role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>:""}
                    {posts.length>0?
                    <div className="row masonryGrid" id="myBlog" style={{minHeight:`${height}px`}}>
                        {posts.map(post => 
                        <div key={post.id} className="col-md-6 col-lg-4 masonry-item filterNews">
                            <div className="post-item">
                                <div className="thumb" style={{height: 200, backgroundImage:'url(assets/img/shape/bg-01.png)', backgroundSize:'cover', backgroundRepeat:'no-repeat'}}>
                                    <Link to="/details" unstable_viewTransition state={{id: post.id}}>
                                        {post.imageUrl.includes(".")? 
                                            <img src={post.imageUrl} alt="" width={400} height={200} />
                                        :
                                            <h6 className="text-center" style={{paddingTop:'25%', color:'#003366'}}>No Image</h6>
                                        }
                                    </Link>
                                    {post.videoLink?post.videoLink.includes(".")?
                                        <a className="icon" href={post.videoLink} target="_blank" rel="noopener noreferrer">
                                            <i className="lnr lnr-camera-video"></i>
                                        </a>
                                        :"":""
                                    }
                                </div>
                                <div className="content">
                                    <h4 className="title">
                                        <Link to="/details" unstable_viewTransition state={{id: post.id}}>{post.title}</Link>
                                    </h4>
                                    <p>{post.description}</p>
                                    <div className="meta bottom meta-bottom">
                                    <div>
                                        <a  className="category"><img className="icon-img" src="assets/img/icons/layer.png" alt="Icon" /> In <span>{post.keywords}</span></a>
                                        <a  className="post-date"><img className="icon-img" src="assets/img/icons/calender.png" alt="Icon" /> {post.dateCreation.split('T')[0]}</a>
                                    </div>
                                    <div className="post-share">
                                        <a  className="icon-share"><img className="icon-img" src="assets/img/icons/share.png" alt="Icon" /></a>
                                        <ul>
                                            <li><a className="facebook" href={"https://twitter.com/share?url="+currentUrl+"&text="+post.title} target="_blank"><i className="social_twitter"></i></a></li>
                                            <li><a className="twitter" href={"https://www.facebook.com/sharer/sharer.php?u="+currentUrl} target="_blank"><i className="social_facebook"></i></a></li>
                                            <li><a className="facebook" href={"https://www.linkedin.com/shareArticle?url="+currentUrl+"&title="+post.title+"&summary="+post.description+"&source="+currentUrl} target="_blank"><i className="social_linkedin"></i></a></li>
                                        </ul>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        )}
                    </div>
                    :
                    <div className="row masonryGrid" style={{minHeight:'100px'}}>
                        <h6 className="text-center" style={{paddingTop:'20%', color:'#003366'}}>No data</h6>
                    </div>
                    }
                    </div>
                    <div className="swiper-container blog-slider-container">
                        {posts.length> 5? 
                            <div onClick={handleNext} className="swiper-button-next"><i className="lnr lnr-arrow-right"></i></div>
                        :""}
                        {page > 1? 
                            <div onClick={handlePreviews} className="swiper-button-prev"><i className="lnr lnr-arrow-left"></i></div>
                            :""
                        }
                    </div>
                </div>
            </section>
            <Footer duration={1200}/>
        </div>
    )
}

export default Blog;