import React, { useEffect } from "react";
import Nav from "../shared/nav";
import Footer from "../shared/footer";
import AOS from 'aos';
import 'aos/dist/aos.css';

function Logiciel() {
    useEffect(() => {
        AOS.init();
    },[])

    return(
        <div>
            <Nav />
            <main className="main-content site-wrapper-reveal">
                <section className="page-title-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="page-title-content text-center">
                                    <h2 className="title" style={{color:'#003366'}}><span>DÉVELOPPEMENT LOGICIELS</span></h2>
                                </div>
                            </div>
                            <div className="shape-layer">
                                <div className="shape shape-one"></div>
                                <div className="shape shape-two"></div>
                                <div className="shape shape-three"></div>
                                <div className="shape shape-four"></div>
                                <div className="shape shape-five"></div>
                                <div className="shape shape-six"></div>
                                <div className="shape shape-seven"></div>
                                <div className="shape shape-eight"></div>
                            </div>
                        </div>
                    </div>
                </section>
                
                <section>
                    <div className="container lg-pt-0 pt-45">
                        <div className="row divider-content-style1" data-aos="fade-down" data-aos-duration="1000">
                            <div className="col-lg-12 col-xl-4 offset-xl-1 p-3">
                                <div className="thumb p-8">
                                    <img className="ml-[10%] mb-10" src="assets/img/photos/cece.jpg" width={480} alt="Image" />
                                    <br />
                                </div>
                            </div>
                            <div className="col-lg-12 col-xl-6 offset-xl-1">
                                <div className="content">
                                    <div className="section-title">
                                        <h5 className="text-black text-bold">DÉVELOPPEMENT LOGICIELS</h5>
                                        <p>Nous concevons, architecturons et développons des produits logiciels élégants. Les applications que nous concevons offrent des expériences utilisateur convaincantes et de beaux designs visuels. Nous utilisons des technologies modernes comme iOS, Android, ReactJs, Xamarin, Node.js, Kotlin pour créer des produits Web responsives, mobiles et desktop robustes.</p>
                                        <p className="mb-0">Nous sommes spécialisés dans le développement de solutions automatisées variées dans le domaine de la gestion des ressources humaines et des opérations en entreprise, qui sont conçues pour utiliser les normes internationales. Nous faisons partie d’une poignée d’entreprises qui peuvent transformer de simples croquis en produits complets qui fonctionnent sur n’importe quel appareil. La concentration est ce qui crée de la valeur, c’est pourquoi nous utilisons notre processus éprouvé pour faire franchir la ligne d’arrivée à votre première version de la solution.</p>
                                        <ul class="list-style">
                                            <li><i class="icon_box-checked"></i> Mobilité en Entreprise</li>
                                            <li><i class="icon_box-checked"></i> Développement IoT pour Les Industries</li>
                                            <li><i class="icon_box-checked"></i> Logiciel Desktop</li>
                                            <li><i class="icon_box-checked"></i> Developpement Mobile et Web</li>
                                        </ul>
                                        <p>LogiqSoft est alimenté par des professionnels hautement qualifiés de professeurs, de consultants, d'ingénieurs, de développeurs et de cadres équipés des derniers outils de gestion et IT et travaille 24 heures sur 24 pour assurer la livraison en temps voulu de vos applications avec la meilleure qualité</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                <div className="shape-thumb-style1">
                    <img src="assets/img/shape/service-details-shape.png" alt="Image" />
                </div>
                </section>

                <section>
                    <div className="container lg-pt-0 pt-45">
                        <div className="row divider-content-style1">
                            <div className="col-lg-12 col-xl-8">
                                <div className="about-content" data-aos="fade-left" data-aos-duration="1000">
                                    <div className="section-title">
                                        <h2>Notre Processus</h2>
                                    </div>
                                    <div className="accordian-content">
                                        <div className="accordion" id="accordionStyle">
                                            <div className="accordion-item">
                                                <div className="accordion-header" id="headingOne">
                                                    <button className="accordion-button" type="button" data-bs-toggle="collapse"
                                                        data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                        Rassemblement des exigences
                                                        <i className="icon icon-plus icon_plus"></i>
                                                        <i className="icon icon-minus icon_minus-06"></i>
                                                    </button>
                                                </div>
                                                <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne"
                                                    data-bs-parent="#accordionStyle">
                                                    <div className="accordion-body">
                                                    Vous devez évaluer les besoins de votre entreprise, puis identifier des objectifs spécifiques une fois que vous avez décidé de développer une application personnalisée. Au cours de la phase initiale de collecte des exigences, nous organisons des réunions avec votre équipe pour recueillir toutes les exigences du projet.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <div className="accordion-header" id="headingTwo">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                        data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                            Analyse des besoins et planification
                                                        <i className="icon icon-plus icon_plus"></i>
                                                        <i className="icon icon-minus icon_minus-06"></i>
                                                    </button>
                                                </div>
                                                <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo"
                                                    data-bs-parent="#accordionStyle">
                                                    <div className="accordion-body">
                                                    Nous commençons à analyser les exigences pour développer une base solide pour la solution logicielle. C'est pourquoi l'analyse et la planification des besoins sont l'une des étapes les plus cruciales de l'ensemble du processus de développement. À cette étape, vos objectifs sont convertis en un plan. Avant de passer à l'étape de développement, les exigences doivent être documentées puis vérifiées.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <div className="accordion-header" id="headingThree">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                        data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                        Conception visuelle et technique
                                                        <i className="icon icon-plus icon_plus"></i>
                                                        <i className="icon icon-minus icon_minus-06"></i>
                                                    </button>
                                                </div>
                                                <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree"
                                                    data-bs-parent="#accordionStyle">
                                                    <div className="accordion-body">
                                                    Chaque solution logicielle exige une approche spécifique ; Ainsi, la conception de l'architecture du système est importante pour chaque projet. La conception interne de chaque module du logiciel est détaillée dans le document SRS. La modularité de la conception et l'évaluation des risques sont les paramètres pris en compte à cette étape.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <div className="accordion-header" id="headingThree">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                        data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                        Développement
                                                        <i className="icon icon-plus icon_plus"></i>
                                                        <i className="icon icon-minus icon_minus-06"></i>
                                                    </button>
                                                </div>
                                                <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree"
                                                    data-bs-parent="#accordionStyle">
                                                    <div className="accordion-body">
                                                    Cette phase est la plus longue. Sur la base du document de spécification des exigences logicielles et de la conception visuelle et technique, notre équipe commence à programmer le logiciel. Le codage pourrait être accompli de manière transparente si les étapes précédentes étaient réalisées méticuleusement. En fonction du type de modèle de développement logiciel, chaque équipe de développement effectue l'activité de développement sous un angle différent.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <div className="accordion-header" id="headingThree">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                        data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                        Tests
                                                        <i className="icon icon-plus icon_plus"></i>
                                                        <i className="icon icon-minus icon_minus-06"></i>
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <div className="accordion-header" id="headingThree">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                        data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                        Développement et maintenance
                                                        <i className="icon icon-plus icon_plus"></i>
                                                        <i className="icon icon-minus icon_minus-06"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                            <div className="col-lg-12 col-xl-3 offset-xl-1">
                                <div className="content">
                                    <div className="section-title" data-aos="fade-right" data-aos-duration="1000">
                                        <h4 className="text-black text-bold">CE QUI FAIT NOTRE FORCE</h4>
                                        <ul class="list-style">
                                            <li><i class="icon_box-checked"></i> Fournir des applications sécurisées, fiables et évolutives qui aident les entreprises à exceller dans l'économie actuelle qui évolue rapidement.</li>
                                            <li><i class="icon_box-checked"></i> Assurer une qualité constante en garantissant la haute qualité de nos livrables.</li>
                                            <li><i class="icon_box-checked"></i> Obtenir la satisfaction du client en comprenant parfaitement ses besoins et en y répondant dans les délais de mise sur le marché les plus courts.</li>
                                            <li><i class="icon_box-checked"></i> Comprendre en profondeur les exigences commerciales des clients.</li>
                                            <li><i class="icon_box-checked"></i> Fournir des solutions informatiques de bout en bout selon les standards.</li>
                                            <li><i class="icon_box-checked"></i> Mettre en œuvre des solutions innovantes de pointe</li>
                                            <li><i class="icon_box-checked"></i> Garantir la haute qualité de nos livrables.</li>
                                            <li><i class="icon_box-checked"></i> Rechercher l'amélioration continue.</li>
                                            <li><i class="icon_box-checked"></i> Adopter les normes internationales et les meilleures pratiques</li>
                                            <li><i class="icon_box-checked"></i> L'accent mise sur la confidentialité des informations liées à nos clients</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="shape-thumb-style1">
                        <img src="assets/img/shape/service-details-shape.png" alt="Image" />
                    </div>
                </section>
            </main>
            <Footer duration={1200} />
        </div>
    )
}

export default Logiciel;